<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Link, usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
const route = inject('route');
const props = defineProps({
    label: String,
    items: Object,
    location: String,
});

const currentRoute = computed(() => usePage().props.current_route_name);

const current = computed(() => {
    let allCurrent = props.items.map((item) => {
        const routeWithoutLang = currentRoute.value.substring(currentRoute.value.indexOf('.') + 1);

        if (item.routeName?.endsWith('.index')) {
            return (
                item.routeName?.split('.').slice(0, -1).join('.') == routeWithoutLang.split('.').slice(0, -1).join('.')
            );
        }

        return routeWithoutLang.substring(routeWithoutLang.indexOf('.') + 1) === item.routeName;
    });
    return allCurrent.some((item) => item === true);
});
</script>

<template>
    <Popover class="relative" v-slot="{ open }">
        <PopoverButton
            :class="[
                open || current ? 'bg-white/10 ' : '',
                'px-4 py-2 hover:bg-white/10 rounded-lg flex items-center transition duration-150',
            ]"
        >
            <span>{{ label }}</span>
            <ChevronDownIcon
                :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']"
                aria-hidden="true"
            />
        </PopoverButton>

        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <PopoverPanel
                class="absolute right-0 z-20 w-screen max-w-xs px-2 mt-3 transform sm:px-0"
                :class="location === 'top' ? 'bottom-0' : ''"
            >
                <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="relative grid bg-white divide-y divide-gray-500-100">
                        <template v-for="item in items" :key="item.routeName">
                            <template v-if="typeof item.group == 'undefined'">
                                <Link
                                    :href="route(item.routeName)"
                                    class="block px-4 py-2 transition duration-150 ease-in-out rounded-md hover:bg-orange hover:text-white text-blue"
                                >
                                    <p class="text-base font-medium">{{ item.label }}</p>
                                </Link>
                            </template>
                            <template v-else>
                                <div
                                    class="block px-4 py-2 transition duration-150 ease-in-out divide-y rounded-md text-blue divide-gray-500-100"
                                >
                                    <p class="text-base font-bold">{{ item.label }}</p>
                                </div>
                                <template v-for="subItem in item.group" :key="subItem.routeName">
                                    <Link
                                        :href="route(subItem.routeName)"
                                        class="block py-2 pl-8 pr-4 transition duration-150 ease-in-out rounded-md hover:bg-orange hover:text-white text-blue"
                                    >
                                        <p class="text-base font-medium">{{ subItem.label }}</p>
                                    </Link>
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>
