import { auth, db, firebaseEnabled } from '@/firebaseConfig';
import { getAuth, signInWithCustomToken } from '@firebase/auth';
import {
    collection,
    collectionGroup,
    doc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    updateDoc,
    where,
} from '@firebase/firestore';
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';

const documentLimit = 50;

export const useChatStore = defineStore('chat', {
    state: () => {
        return {
            type: 'employer',
            selectedGroup: '',
            lastGroupDocument: null,
            groups: [],
            messages: [],
            excludeFromNewGroup: [],
            unreadGroups: 0,

            // Listeners
            getGroupsUnsubscribe: null,
            selectGroupUnsubscribe: null,
        };
    },
    actions: {
        async authenticateWithFirebase() {
            if (!firebaseEnabled) {
                console.warn('Firebase config not set. Firebase will not be available.');
                return;
            }

            if (getAuth().currentUser !== null) {
                return;
            }

            try {
                const tokenResponse = await axios.get('/api/v0/firebase-auth-token');
                const token = tokenResponse.data.token;

                // Quickly check if authentication happened in the background
                if (getAuth().currentUser !== null) {
                    return;
                }

                await signInWithCustomToken(getAuth(), token);
            } catch (e) {
                console.error(e);
            }
        },
        async getGroups(append = false) {
            if (firebaseEnabled) {
                try {
                    let q;
                    if (this.type === 'employer') {
                        if (append && this.lastGroupDocument !== null) {
                            q = query(
                                collection(db, 'users', auth.currentUser.uid, 'groups'),
                                where('locationId', '==', usePage().props.teamable.id),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                startAfter(this.lastGroupDocument), // TODO Can we prevent setting the query twice?
                                limit(documentLimit)
                            );
                        } else {
                            q = query(
                                collection(db, 'users', auth.currentUser.uid, 'groups'),
                                where('locationId', '==', usePage().props.teamable.id),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                limit(documentLimit)
                            );
                        }
                    } else {
                        if (append && this.lastGroupDocument !== null) {
                            q = query(
                                collection(db, 'users', auth.currentUser.uid, 'groups'),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                startAfter(this.lastGroupDocument), // TODO Can we prevent setting the query twice?
                                limit(documentLimit)
                            );
                        } else {
                            q = query(
                                collection(db, 'users', auth.currentUser.uid, 'groups'),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                limit(documentLimit)
                            );
                        }
                    }

                    this.getGroupsUnsubscribe = onSnapshot(
                        q,
                        (querySnapshot) => {
                            const groups = [];
                            let unreadGroups = 0;

                            querySnapshot.forEach((doc) => {
                                const group = doc.data();

                                groups.push(group);

                                if (this.type === 'freelancer') {
                                    this.excludeFromNewGroup.push(group.locationId);
                                }

                                if (
                                    group.sentById !== auth.currentUser.uid &&
                                    group.read === false &&
                                    group.sentAt !== null
                                ) {
                                    unreadGroups++;
                                }

                                if (querySnapshot.docs.length >= documentLimit) {
                                    this.lastGroupDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
                                } else {
                                    this.lastGroupDocument = null;
                                }
                            });

                            if (append) {
                                this.$patch({
                                    groups: [...this.groups, ...groups],
                                    unreadGroups: this.unreadGroups + unreadGroups,
                                });
                            } else {
                                this.$patch({
                                    groups,
                                    unreadGroups,
                                });
                            }
                        },
                        (error) => {
                            console.error(error);
                        }
                    );

                    if (this.type === 'freelancer') {
                        return;
                    }

                    const excludeFromNewGroup = [];
                    excludeFromNewGroup.push(auth.currentUser.uid);
                    const members = query(
                        collectionGroup(db, 'groups'),
                        where('locationId', '==', usePage().props.teamable.id),
                        where('members', '!=', null) // If we don't include this, 'user/groups/{id}' is also included
                    );

                    const membersSnapshot = await getDocs(members);
                    membersSnapshot.forEach((doc) => {
                        excludeFromNewGroup.push(doc.data().members);
                    });

                    this.excludeFromNewGroup = excludeFromNewGroup.flat();
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async getGroupsForStaff(id, type, append = false) {
            if (firebaseEnabled) {
                try {
                    let q;

                    if (type === 'locations') {
                        if (append && this.lastGroupDocument !== null) {
                            q = query(
                                collection(db, 'groups'),
                                where('locationId', '==', id),
                                startAfter(this.lastGroupDocument),
                                limit(documentLimit)
                            );
                        } else {
                            q = query(collection(db, 'groups'), where('locationId', '==', id), limit(documentLimit));
                        }
                    }
                    if (type === 'workers') {
                        if (append && this.lastGroupDocument !== null) {
                            q = query(
                                collection(db, 'users', id, 'groups'),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                startAfter(this.lastGroupDocument),
                                limit(documentLimit)
                            );
                        } else {
                            q = query(
                                collection(db, 'users', id, 'groups'),
                                orderBy('sentAt', 'desc'),
                                orderBy('displayName'),
                                limit(documentLimit)
                            );
                        }
                    }

                    this.getGroupsUnsubscribe = onSnapshot(
                        q,
                        async (querySnapshot) => {
                            const groups = [];

                            const userIds = [];

                            querySnapshot.forEach((doc) => {
                                const group = doc.data();

                                if (type === 'locations') {
                                    group.id = doc.id;
                                    if (group.members !== null) {
                                        group.members.forEach((member) => {
                                            if (!userIds.includes(member)) {
                                                userIds.push(member);
                                            }
                                        });
                                    }
                                }

                                groups.push(group);
                            });

                            const usersFromFirestore = await this.getUsers(userIds);

                            if (type === 'locations') {
                                groups.map((group) => {
                                    if (group.members !== null) {
                                        group.members = group.members.map((member) => {
                                            return usersFromFirestore.find((user) => user.id === member);
                                        });

                                        group.displayName = group.members[0].nickname + ' ' + group.members[0].lastName;
                                        group.imageUrl = group.members[0].imageUrl;

                                        return group;
                                    }
                                });
                            }

                            if (querySnapshot.docs.length >= documentLimit) {
                                this.lastGroupDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
                            } else {
                                this.lastGroupDocument = null;
                            }

                            if (append) {
                                this.groups = [...this.groups, ...groups];
                            } else {
                                this.groups = groups;
                            }
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async selectGroup(id, updateRead = false, members = undefined) {
            if (firebaseEnabled) {
                try {
                    if (typeof this.selectGroupUnsubscribe === 'function') {
                        this.selectGroupUnsubscribe();
                    }

                    const q = query(collection(db, 'groups', id, 'messages'), orderBy('sentAt', 'desc'));

                    this.selectGroupUnsubscribe = onSnapshot(
                        q,
                        (querySnapshot) => {
                            const messages = [];

                            querySnapshot.forEach((doc) => {
                                const message = doc.data({ serverTimestamps: 'estimate' });

                                if (this.type === 'staff' && members !== undefined) {
                                    message.ownMessage = !members.includes(message.sentById);
                                } else {
                                    message.ownMessage = message.sentById === auth.currentUser.uid;
                                }
                                message.sentAt = message?.sentAt?.toDate() ?? new Date();
                                // message.ownMessage = Math.random() < 0.5;
                                messages.push(message);
                            });

                            const groupedMessages = messages.reduce((acc, message) => {
                                const date = dayjs(message['sentAt']).startOf('minute').toDate();

                                acc[date] ??= [];
                                acc[date].unshift(message);

                                return acc;
                            }, {});

                            this.messages = groupedMessages;
                            this.selectedGroup = id;
                        },
                        (error) => {
                            console.error(error);
                        }
                    );

                    if (updateRead) {
                        const groupRef = doc(db, 'users', auth.currentUser.uid, 'groups', id);

                        await updateDoc(groupRef, {
                            read: true,
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async sendMessage(message) {
            if (firebaseEnabled && this.type === 'employer') {
                try {
                    if (!this.selectedGroup) {
                        throw new Error('Not in group');
                    }

                    await setDoc(doc(collection(db, 'groups', this.selectedGroup, 'messages')), {
                        message: message,
                        sentAt: serverTimestamp(),
                        sentById: auth.currentUser.uid,
                        sentByName: usePage().props.user.nickname,
                        imageUrl: auth.currentUser.photoURL,
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        },
        async getUsers(ids) {
            const batches = [];

            while (ids.length) {
                const batch = ids.splice(0, 10);

                const q = query(collection(db, 'users'), where('id', 'in', [...batch]));

                const querySnapshot = await getDocs(q);
                // return querySnapshot.docs.map((doc) => doc.data());
                batches.push(querySnapshot.docs.map((doc) => doc.data()));
            }

            const allPromises = await Promise.all(batches);

            return allPromises.flat();
        },
    },
});
