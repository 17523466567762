<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
const route = inject('route');
const props = defineProps({
    label: String,
    routeName: String,
});
const currentRoute = computed(() => usePage().props.current_route_name);

const current = computed(() => {
    const routeWithoutLang = currentRoute.value.substring(currentRoute.value.indexOf('.') + 1);

    if (props.routeName?.endsWith('.index')) {
        return props.routeName?.split('.').slice(0, -1).join('.') == routeWithoutLang.split('.').slice(0, -1).join('.');
    }

    return routeWithoutLang.substring(routeWithoutLang.indexOf('.') + 1) === props.routeName;
});
</script>

<template>
    <Link class="flex-col text-center flex-center" :href="route(routeName)">
        <div :class="{ 'bg-white/10 rounded-lg': current }" class="px-4 py-2 hover:bg-white/10 hover:rounded-lg">
            {{ label }}
        </div>
    </Link>
</template>
