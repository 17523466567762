import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        previousRoutes: useSessionStorage(
            'store.navigation.previous-routes',
            {}
        ),
        history: useSessionStorage('store.navigation.history', []),
    }),

    actions: {
        setRoute(name, url) {
            this.previousRoutes[name] = url;
            if (this.history[0] !== url) {
                this.history.unshift(url);
            }
        },
    },
});
