// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import {
    getFirestore,
    connectFirestoreEmulator,
    Firestore,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";
import {
    getFunctions,
    connectFunctionsEmulator,
    Functions,
} from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

let firebaseEnabled: boolean,
    app: FirebaseApp,
    db: Firestore,
    auth: Auth,
    functions: Functions;

if (
    firebaseConfig.apiKey !== "" &&
    firebaseConfig.authDomain !== "" &&
    firebaseConfig.projectId !== "" &&
    firebaseConfig.storageBucket !== "" &&
    firebaseConfig.messagingSenderId !== "" &&
    firebaseConfig.appId !== ""
) {
    firebaseEnabled = true;
    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    db = getFirestore(app);
    auth = getAuth(app);
    functions = getFunctions(app);

    if (
        import.meta.env.VITE_FIREBASE_USE_EMULATOR === "true" &&
        firebaseEnabled
    ) {
        connectFirestoreEmulator(db, "127.0.0.1", 8080);
        connectAuthEmulator(auth, "http://127.0.0.1:9099");
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
} else {
    firebaseEnabled = false;
    if (import.meta.env.DEV) {
        console.warn(
            "Firebase config not set. Firebase will not be available."
        );
    }
}

export { firebaseEnabled, db, auth };
